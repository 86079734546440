<template>
  <div>
    <app-header></app-header>

    <div class="login">
      <div class="login-logo text-center">
        <img src="../../assets/images/logo.png" alt="" class="" />
        <p class="text-center">{{$t('The account has been confirmed successfully')}}</p>
      </div>
      <div class="text-center">
        <img src="../../assets/images/varfy.gif" />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "../../layouts/header.vue";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {};
  },

  methods: {},
  async created() {
    const phone = this.$route.query.phone;
    const code = this.$route.query.varifyMessage;
    this.$http.get(`auth/activateUser?phone=${phone}&varifyMessage=${code}`).then(
      (response) => {
        this.$toast.add({
          severity: "success",
          summary: this.$t("Done Successfully"),
          detail: this.$t("Register Successfully"),
          life: 3000,
        });
       localStorage.homeHere = JSON.stringify(response.data.userData);
        setTimeout(() => {
          this.$router.push("/login");
        }, 5000);
      },
      (err) => {
        this.$toast.add({
          severity: "error",
          summary: this.$t("There error"),
          detail: err.response.data.message,
          life: 3000,
        });
      }
    );
  },
};
</script>
<style></style>
